<template>
  <v-card>
    <v-card-title class="headline sdark">
      <v-layout align-center>
        <v-flex>
          <span class="primary--text text-uppercase font-weight-medium"
            >Modify Profile</span
          >
        </v-flex>
        <v-flex shrink>
          <v-btn color="primary" icon flat @click.native="onClickClose">
            <v-icon>close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-card-title>
    <v-card-text>
      <v-container grid-list-md>
        <v-layout row wrap align-center>
          <div v-if="showUsernameAlert" id="username-alert">
            <strong>Username change</strong>
            <div id="alert-text">You will be required to log in again after submitting the update.</div>
         </div>
          <v-flex xs12>
            <v-alert :value="error" outline color="primary">
              {{ error && error.message }}
            </v-alert>
          </v-flex>
          <v-flex xs12>
            <d-form
              ref="form"
              v-model="valid"
              @onSubmit="onClickChange"
              @onCancel="onClickClose"
              includeCancel
              v-bind="{ error, status }"
            >
              <v-text-field
                color="primary"
                name="name"
                label="Name"
                id="name"
                v-model="currentUser.name"
                :rules="[rules.required, rules.minName]"
              />
              <v-text-field
                color="primary"
                name="email"
                label="Email"
                :rules="[...rules.emailRules, uniqueEmail]"
                id="email"
                type="email"
                v-model="currentUser.email"
                @input="() => validateUniqueEmail()"
              />
              <v-text-field
                color="primary"
                name="username"
                label="Username"
                :rules="[rules.required, rules.minName, uniqueUsername]"
                id="username"
                v-model="currentUser.username"
                @input="() => onUsernameChange()"
              />
              <SubHeader label="Unit Settings" />
              <v-radio-group
                row
                style="margin-top:0px"
                v-model="currentUser.settings.displayUnit"
              >
                <v-radio
                  color="primary"
                  key="km"
                  label="km/m"
                  value="metric"
                  checked
                />
                <v-radio
                  color="primary"
                  key="mi"
                  label="mi/ft"
                  value="imperial"
                />
              </v-radio-group>
              <v-tooltip
                :open-delay="tooltipDelay"
                color="sdark"
                max-width="430"
                right
              >
                <v-switch
                  slot="activator"
                  class="py-0"
                  label="MGRS Notation"
                  v-model="currentUser.settings.mgrsEnabled"
                  color="primary"
                  type="Boolean"
                />
                <span>
                  Convert all latitude/longitude references to MGRS
                </span>
              </v-tooltip>
              <v-tooltip
                :open-delay="tooltipDelay"
                color="sdark"
                max-width="430"
                right
              >
                <v-switch
                  slot="activator"
                  class="py-0"
                  label="MIL Angles"
                  v-model="currentUser.settings.milsEnabled"
                  color="primary"
                  type="Boolean"
                />
                <span>
                  Convert all degrees angles to Milliradians (NATO)
                </span>
              </v-tooltip>
              <v-text-field
                autofocus
                color="primary"
                label="Current Password"
                autocomplete="current-password"
                id="currentPassword"
                :rules="[rules.required]"
                v-model="currentUser.current_password"
                :append-icon="
                  isCurrentVisible ? 'visibility' : 'visibility_off'
                "
                @click:append="toggleCurrentVisible"
                :type="isCurrentVisible ? 'text' : 'password'"
              />
              <SubHeader label="Change Password" />
              <v-text-field
                ref="password"
                name="password"
                autocomplete="new-password"
                label="New Password"
                data-vv-name="password"
                data-vv-delay="300"
                :rules="[...rules.validUnrequiredPassword, validatePasswords('password')]"
                v-model="currentUser.password"
                :append-icon="
                  isPasswordVisible ? 'visibility' : 'visibility_off'
                "
                @click:append="togglePasswordVisible"
                :type="isPasswordVisible ? 'text' : 'password'"
              />
              <v-text-field
                label="Password Confirmation"
                autocomplete="new-password"
                data-vv-name="password_confirmation"
                data-vv-delay="300"
                target="password"
                :rules="[validatePasswords('password_confirmation')]"
                v-model="currentUser.password_confirmation"
                :append-icon="
                  isConfirmationVisible ? 'visibility' : 'visibility_off'
                "
                @click:append="toggleConfirmationVisible"
                :type="isConfirmationVisible ? 'text' : 'password'"
              />
            </d-form>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import { FormMixin } from '@/components/Mixins'
import SubHeader from "@/components/Forms/SubHeader.vue";

export default {
  name: 'ModifyProfileCard',
  components: {SubHeader},
  mixins: [FormMixin],
  data: () => ({
    valid: false,
    isCurrentVisible: false,
    isPasswordVisible: false,
    isConfirmationVisible: false,
    passwordValidation: false,
    passwordConfirmationValidation: false,
    currentUser: {
      name: null,
      email: '',
      username: '',
      company: '',
      phone: '',
      password: '',
      password_confirmation: '',
      current_password: '',
      settings: {}
    },
    uniqueEmail: false,
    uniqueUsername: false,
    isDifferentUsername: false,
  }),
  computed: {
    ...mapState('users', ['status', 'usersList', 'error', 'user']),
    ...mapGetters('users', ['isAuthorized']),
    filteredUsers() {
      const { usersList, currentUser } = this
      return usersList.filter(user => user.id != currentUser.id)
    },
    existingEmails() {
      return this.filteredUsers.map(user => user.email)
    },
    existingUsernames() {
      return this.filteredUsers.map(user => user.username)
    },
    showUsernameAlert() {
      return this.isDifferentUsername
    }
  },
  methods: {
    ...mapActions('users', ['UPDATE_PROFILE', 'CLEAR_ERROR', 'fetchListOfUsers']),
    async onClickChange() {
      const {
        name,
        email,
        username,
        company,
        phone,
        password,
        password_confirmation,
        current_password,
        settings
      } = this.currentUser

      let user = {
        id: this.user.id,
        name,
        email,
        username,
        company,
        phone,
        password,
        password_confirmation,
        current_password,
        settings
      }

      await this.UPDATE_PROFILE(user)
      if (this.status === 'OK') {
        this.showPasswordStatusAlert('success')
        this.onClickClose()
      } else {
        this.showPasswordStatusAlert('error')
      }
    },
    showPasswordStatusAlert(status) {
      const isSuccess = status === 'success';
      const title = isSuccess ? "Profile updated" : "Error Updating User's profile";
      const message = isSuccess ? 'Profile has been updated successfully' : `Reason: ${this.error}`
      this.$bus.$emit('APP_MESSAGE', {
        flush: 'manual',
        color: status,
        title,
        message,
      })
    },
    toggleCurrentVisible() {
      this.isCurrentVisible = !this.isCurrentVisible
    },
    togglePasswordVisible() {
      this.isPasswordVisible = !this.isPasswordVisible
    },
    toggleConfirmationVisible() {
      this.isConfirmationVisible = !this.isConfirmationVisible
    },
    onClickClose() {
      this.resetForm()
      this.$emit('clickClose')
    },
    resetForm() {
      this.$refs.form.$refs.form.reset()
    },
    validatePasswords(type) {
      const {password, password_confirmation} = this.currentUser
      if (password === password_confirmation) return true

      if (type === 'password') {
        return 'The password does not match the password confirmation'
      } else {
        return 'The password confirmation does not match the password'
      }
    },
    validateUniqueEmail() {
      const { currentUser, existingEmails } = this
      this.uniqueEmail =
        !existingEmails.includes(currentUser.email) ||
        'Email already used for another user'
    },
    validateUniqueUsername() {
      const { currentUser, existingUsernames } = this
      this.uniqueUsername =
        !existingUsernames.includes(currentUser.username) ||
        'Username already used for another user'
    },
    onUsernameChange() {
      const { currentUser: { username }, user } = this
      if (username !== user.username) {
        this.isDifferentUsername = true
      } else {
        this.isDifferentUsername = false
      }
      this.validateUniqueUsername()
    },
    resetData() {
      this.CLEAR_ERROR()
    }
  },
  created() {
    this.resetData()
  },
  beforeDestroy() { 
    this.$bus.$off('OPEN_MODIFY_PROFILE_DIALOG')
  },
  async mounted() {
    this.$store.commit('users/SET_ERROR', null)
    this.$store.commit('users/SET_STATUS', null)
    this.resetData()
    this.currentUser = this.user
    if (this.isAuthorized('manager')) {
      // this is used for the unique validation of username and email, but it would return 403 for any other users
      // this will make the validation in the UI pass all the time because the list of users is empty but the
      // backend will catch it and return a 422
      await this.$store.dispatch('users/fetchListOfUsers')
    }
    this.$bus.$on('OPEN_MODIFY_PROFILE_DIALOG', ({ user }) => {
      this.currentUser = user
    })
  }
}
</script>

<style scoped>
#username-alert{
  padding: 8px 12px 8px 12px;
  margin-bottom: 20px;
  background-color: #303030;
}

#alert-text{
  font-size: 12px;
}
</style>
